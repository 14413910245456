// Import the functions you need from the Firebase SDK
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAh7LBnKFcxGfiIozRqzTuHyoAu-zFQZ_4",
  authDomain: "blucollarbookings.firebaseapp.com",
  databaseURL: "https://blucollarbookings-default-rtdb.firebaseio.com",
  projectId: "blucollarbookings",
  storageBucket: "blucollarbookings.firebasestorage.app",
  messagingSenderId: "957858774854",
  appId: "1:957858774854:web:49c7f77c9a56c77e48ef47",
  measurementId: "G-TLD3K6JHGX"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Realtime Database and get a reference to the service
const database = getDatabase(firebaseApp);

export { database };
