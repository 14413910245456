import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import CompaniesList from './components/CompaniesList';
import CompaniesDashboard from './components/CompaniesDashboard'; 
import EmployeeRegistryForm from './components/EmployeeRegistryForm';
import TicketCreation from './components/ticketCreation';  
import ServiceTicket from './components/serviceTicket';  
import CompletedServiceTicket from './components/completedServiceTicket';
import ApprovalServiceTicket from './components/approvalServiceTickets';
import WorkHistory from './components/workHistory';
import AboutUs from './components/AboutUs';  
import Downloads from './components/Downloads'; // Import Downloads component
import logo from './images/BluCollarBookings_transparent-.png';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="company-header">
            <div className="company-logo">
              <img src={logo} alt="BluCollarBookings Logo" />
            </div>
          </div>
          <nav>
            <ul>
              <li><Link to="/" className="App-link">Home</Link></li>
              <li><Link to="/login" className="App-link">Login</Link></li>
              <li><Link to="/work-history" className="App-link">Work History</Link></li>
              <li><Link to="/about-us" className="App-link">About Us</Link></li>
              <li><Link to="/downloads" className="App-link">Downloads</Link></li>
            </ul>
          </nav>
        </header>

        <div className="content-container">
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<CompaniesList />} />
            <Route path="/dashboard" element={<CompaniesDashboard />} />
            <Route path="/register-employee" element={<EmployeeRegistryForm />} />
            <Route path="/create-ticket" element={<TicketCreation />} />  
            <Route path="/service-ticket/:ticketId" element={<ServiceTicket />} />
            <Route path="/completed-service-ticket/:ticketId" element={<CompletedServiceTicket />} />
            <Route path="/approval-service-ticket/:ticketId" element={<ApprovalServiceTicket />} />
            <Route path="/work-history" element={<WorkHistory />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/downloads" element={<Downloads />} /> {/* Add Downloads route */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
