import React from 'react';

function Downloads() {
  return (
    <div className="downloads">
      <h1>Downloads</h1>
      <p>Here you can find all the resources and files available for download.</p>
      <ul>
        <li><a href="/files/blucollarbookings-customer-android.apk" download>BluCollarBookings Client Side - Android v1.0</a></li>
        <li><a href="/files/example-file2.pdf" download>Example File 2</a></li>
        <li><a href="/files/example-file3.pdf" download>Example File 3</a></li>
      </ul>
    </div>
  );
}

export default Downloads;
